import { observer } from 'mobx-react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { PageNotFoundIcon } from 'assets/img';

const PageNotFound = () => {
  return (
    <>
      <Head>
        <title>Page Not Found</title>
        <meta name="robots" content="noindex,nofollow" />
      </Head>

      <div
        className="flex flex-col bg-white min-h-[calc(100vh-170px)] justify-center items-center mx-auto"
        style={{ maxWidth: '1440px' }}>
        <div className="flex flex-col justify-center items-center">
          <Image
            src={PageNotFoundIcon}
            className="bg-white"
            width={170}
            height={170}
            alt="not-found"
          />

          <div className="flex flex-col justify-center">
            <span className="text-[24px] text-center text-primary-black font-inter-semibold mt-3">
              Page not found
            </span>

            <Link href="/" className={`btn-style`}>
              <span className="text-hh-accent-light text-center cursor-pointer mt-3 text-md">
                Return to the main page
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(PageNotFound);
